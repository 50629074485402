// extracted by mini-css-extract-plugin
export var arrow = "imageTextBlock-module--arrow--7kUPj";
export var background_azure = "imageTextBlock-module--background_azure--UIQAb";
export var background_chamois = "imageTextBlock-module--background_chamois--7bFC-";
export var background_delta = "imageTextBlock-module--background_delta--qtOnO";
export var background_dusky_rose = "imageTextBlock-module--background_dusky_rose--dfXm9";
export var background_grain_brown = "imageTextBlock-module--background_grain_brown--SR6pX";
export var background_grenadier = "imageTextBlock-module--background_grenadier--s1eyg";
export var background_grey_chateau = "imageTextBlock-module--background_grey_chateau--QGI49";
export var background_primary_blue = "imageTextBlock-module--background_primary_blue--of5PR";
export var background_regent_st_blue = "imageTextBlock-module--background_regent_st_blue--C7C6r";
export var background_white = "imageTextBlock-module--background_white--xGsZw";
export var button = "imageTextBlock-module--button--+i-cl";
export var buttonWrapper = "imageTextBlock-module--buttonWrapper--8Rxpu";
export var fullwidth = "imageTextBlock-module--fullwidth--1Pidx";
export var height_100 = "imageTextBlock-module--height_100--f5Tmj";
export var height_50 = "imageTextBlock-module--height_50--CheAD";
export var height_75 = "imageTextBlock-module--height_75--f8TsK";
export var hero = "imageTextBlock-module--hero--CjlKM";
export var icon = "imageTextBlock-module--icon--8se3Q";
export var image = "imageTextBlock-module--image--7jlmM";
export var imageTextBlock = "imageTextBlock-module--imageTextBlock--Cw9+Q";
export var image_contain = "imageTextBlock-module--image_contain--d8WD2";
export var image_position_left = "imageTextBlock-module--image_position_left--Bhh7d";
export var image_position_right = "imageTextBlock-module--image_position_right--4PeMX";
export var image_position_underneath = "imageTextBlock-module--image_position_underneath--85d+z";
export var image_text = "imageTextBlock-module--image_text--rlKNf";
export var inner = "imageTextBlock-module--inner--+35a9";
export var reverse_mobile = "imageTextBlock-module--reverse_mobile--FgL54";
export var slanted = "imageTextBlock-module--slanted--0l5no";
export var spacing_0 = "imageTextBlock-module--spacing_0--Ing9f";
export var spacing_l = "imageTextBlock-module--spacing_l--EpDL4";
export var spacing_m = "imageTextBlock-module--spacing_m--CVlbG";
export var spacing_s = "imageTextBlock-module--spacing_s--2PuQc";
export var spacing_xl = "imageTextBlock-module--spacing_xl--DoH3B";
export var spacing_xxl = "imageTextBlock-module--spacing_xxl--E6NN2";
export var spacing_xxxl = "imageTextBlock-module--spacing_xxxl--IBucx";
export var text = "imageTextBlock-module--text--R6lY6";