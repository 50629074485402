import React from 'react';
import {disable} from "./spacer.module.scss";

function Spacer({height = 0, disableMobile = false,...props}) {

    return (
        <div className={disableMobile ? disable : ""} style={{height: height}} />
    );

}

export default Spacer;