import * as React from "react"
import { graphql } from "gatsby"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import Spacer from "../components/01_atoms/Spacer/Spacer"
import Section from "../components/03_organisms/Section/Section"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useTranslation } from "react-i18next"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"

const NotFoundPage = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Layout>
      <ImageTextBlock>
        <Section content align="center">
          <Spacer height={50} />
          <Heading align="center" size="xxl">
            {t("404_title")}
          </Heading>
          <Paragraph align="center">{t("404_body")}</Paragraph>
        </Section>
      </ImageTextBlock>
    </Layout>
  )
}
export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
