import classNames from "classnames"
import * as React from "react"
import Helmet from "react-helmet"
import { AnimateIn } from "../../AnimateIn"
import * as styles from "./imageTextBlock.module.scss"

const ImageTextBlock = ({
  children,
  className,
  height,
  image,
  image_position,
  image_position_mobile,
  image_size,
  fullwidth = true,
  background_color,
  animation,
  inverted_UI,
  slanted,
  id,
  spacing = "",
}) => {
  const imageTextClasses = classNames(
    styles.imageTextBlock,
    styles[`spacing_${spacing}`],
    {
      [styles.image_text]: image || animation,
      [styles.image_position_left]:
        (image || animation) && image_position === "left",
      [styles.image_position_right]:
        (image || animation) && image_position === "right",
      [styles.image_position_underneath]:
        (image || animation) && image_position === "underneath",
      [styles.reverse_mobile]: image_position_mobile === "reverse",
      [styles.image_contain]: (image || animation) && image_size === "contain",
    },
    styles[
      `background_${background_color?.toLowerCase().replaceAll(" ", "_")}`
    ],

    styles[`height_${height?.toLowerCase()}`],

    { [styles.fullwidth]: fullwidth },
    { [styles.slanted]: slanted },
    styles[className]
  )

  const imageSrcSet = image?.relationships.field_media_image.image_style_uri
  const url = image?.relationships.field_media_image.url

  return (
    <div className={imageTextClasses} id={id}>
      {image && !animation && (
        <picture className={styles.image}>
          {image_position === "underneath" && (
            <>
              <source type="image/webp" srcSet={imageSrcSet.extra_wide_webp} />
              <source type="image/png" srcSet={imageSrcSet.extra_wide_png} />
            </>
          )}

          <source type="image/webp" srcSet={imageSrcSet.original_webp} />
          <source type="image/png" srcSet={imageSrcSet.original_png} />

          <source type="image/webp" srcSet={imageSrcSet.landscape_webp} />
          <source type="image/png" srcSet={imageSrcSet.landscape_png} />
          <source type="image/webp" srcSet={imageSrcSet.portrait_webp} />
          <source type="image/png" srcSet={imageSrcSet.portrait_png} />
          <source type="image/webp" srcSet={imageSrcSet.square_webp} />
          <source type="image/png" srcSet={imageSrcSet.square_png} />
          <img src={url} alt={image.field_media_image.alt} />
        </picture>
      )}
      {animation && <div className={styles.image}>{animation}</div>}
      <div className={styles.inner}>
        <AnimateIn>{children}</AnimateIn>
      </div>

      {inverted_UI && (
        <Helmet
          bodyAttributes={{
            class: "inverted-ui",
          }}
        />
      )}
    </div>
  )
}

export default ImageTextBlock
